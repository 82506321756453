import React from 'react';
import Image, { ImageProps } from 'next/image';
import MagicWang from '../../../public/magic-wand.svg';

export const EmailSent = () => {
  const imageProps: ImageProps = {
    src: MagicWang,
    width: '125',
    height: '125',
  };

  return (
    <div className='mx-0 text-center sm:mx-10'>
      <div className='flex flex-col items-center justify-center my-6 mb-2'>
        <h2 className='text-2xl font-bold'>Email sent!</h2>
        <div className='pt-11'>
            Check your inbox to validate.
        </div>
        <div className='pb-24 pt-14 md:pt-16 md:pb-36'>
          <Image {...imageProps} />
        </div>
      </div>
    </div>
  );
};
