import React from 'react';
import { Button } from '../../../components/button';
import { HelpLinks } from '../../../components/help-links';
import { LoginFooterProps as Props } from './LoginFooter.types';

export const LoginFooter: React.FC<Props> = ({
  onForgotPasswordClick,
}) => (
  <footer className='flex flex-col items-center mt-5 md:flex-row md:justify-between md:mb-0'>
    <Button
      className='p-2 mb-10 text-sm font-normal underline md:mb-0'
      onClick={onForgotPasswordClick}
    >
      Forgot your Password?
    </Button>
    <HelpLinks />
  </footer>
);
