import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTransition, animated } from 'react-spring';
import { TooltipProps as Props } from './Tooltip.types';

export const Tooltip: React.FC<Props> = ({
  open = false,
  content,
  placement = 'auto',
  closeOnOutsideClick = true,
  trigger = 'click',
  children,
  className = 'border-r rounded p-0 border-darkGrey z-20',
  arrowClassName,
  ...rest
}) => {
  const [isVisible, setVisible] = React.useState<boolean>(open);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger,
    placement,
    visible: isVisible,
    closeOnOutsideClick,
    onVisibleChange: setVisible,
    ...rest,
  });

  const fadeIn = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 100 },
  });

  if (typeof window === 'undefined') return null;

  return (
    <>
      <div ref={setTriggerRef}>{children}</div>

      {ReactDOM.createPortal(
        (fadeIn.map(
          ({ item, key, props }) => item && (
            <animated.div
              key={key}
              ref={setTooltipRef}
              {...getTooltipProps({
                className: classnames('tooltip-container', className),
                style: { ...props },
              })}
            >
              {content}
              <div {...getArrowProps({
                className: classnames('tooltip-arrow', arrowClassName),
              })} />
            </animated.div>
          ),
        )
        ), document.body,
      )}
    </>
  );
};
