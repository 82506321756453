import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useRouter } from 'next/router';
import { AuthTokenResponse, PasswordlessLoginWithPasswordProps as Props } from './PasswordlessLogin.types';
import { TextField } from '../../components/textfield';
import { Button } from '../../components/button';
import { IS_TEST_USER, OAuth2Credentials, RECAPTCHA_KEY } from '../../constants';
import { isOAuthErrorResponse } from '../../utils/api';

export const PasswordlessLoginWithPassword: React.FC<Props> = ({
  inputs, handleInputChange, setRecaptchaToken, state,
}) => {
  const router = useRouter();
  const { username, password, recaptchaToken } = inputs;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const resetRecaptcha = () => {
    setRecaptchaToken('');
    recaptchaRef.current?.reset();
  };

  const handleSignInWithPassword = async () => {
    setErrorMessage('');
    setIsLoading(true);

    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('password', password);
    params.set('recaptcha', recaptchaToken);
    params.set('state', state);
    params.set('username', username);
    const requestBody = params.toString();

    try {
      const { data: { urlForRedirection } } = await axios.post<AuthTokenResponse>('/token', requestBody, {
        headers: {
          Authorization: `Basic ${btoa(`${OAuth2Credentials.id}:${OAuth2Credentials.secret}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      await router.push(urlForRedirection);
    } catch (error) {
      const incorrectPasswordErrorMessage = 'Incorrect username or password';
      if (axios.isAxiosError(error) && isOAuthErrorResponse(error.response.data)) {
        setErrorMessage(error.response.data.errorDescription ?? incorrectPasswordErrorMessage);
      } else {
        setErrorMessage(incorrectPasswordErrorMessage);
      }
      setIsLoading(false);
      resetRecaptcha();
    }
  };

  const isSignInButtonDisabled = !password || (!recaptchaToken && !IS_TEST_USER) || isLoading;

  return (
    <div>
      <div className="flex items-center justify-center my-6 mb-6">
        <h2 className="text-2xl font-bold">Enter password</h2>
      </div>

      <TextField
        name='password'
        type='password'
        className='mb-4'
        variant='filled'
        placeholder='Password'
        value={password}
        onChange={handleInputChange}
      />

      <div className='w-full mb-4 text-center md:my-6'>
        <div className='inline-block transform scale-75 md:origin-top-left md:scale-100' >
          <ReCAPTCHA
            data-testid='self-registration-recaptcha'
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={setRecaptchaToken}
          />
        </div>
      </div>

      <Button
        className='mb-4 bg-darkGrey border-darkGrey'
        variant='contained'
        fullWidth
        disabled={isSignInButtonDisabled}
        onClick={handleSignInWithPassword}
      >
        Sign in
      </Button>

      {errorMessage && <p className='p-2 text-center text-red-600'>{errorMessage}</p>}
    </div>
  );
};
