import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { PasswordlessLoginSteps, PasswordlessLoginWithoutPasswordProps as Props } from './PasswordlessLogin.types';
import { TextField } from '../../components/textfield';
import { Button } from '../../components/button';
import { IS_TEST_USER, RECAPTCHA_KEY } from '../../constants';
import { isValidEmail } from '../../utils/strings';
import { isApiErrorResponse } from '../../utils/api';

export const PasswordlessLoginWithoutPassword: React.FC<Props> = ({
  inputs, handleInputChange, setRecaptchaToken, setStep, state,
}) => {
  const { username, recaptchaToken } = inputs;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const resetRecaptcha = () => {
    setRecaptchaToken('');
    recaptchaRef.current?.reset();
  };

  const handleSignInWithoutPassword = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      const response = await axios.post(`/passwordless?state=${state}`, {
        email: username,
        recaptcha: recaptchaToken,
      });

      if (response.status === 204) {
        return setStep(PasswordlessLoginSteps.EMAIL_SENT);
      }

      throw new Error('Unexpected error');
    } catch (error) {
      if (!axios.isAxiosError(error) || !isApiErrorResponse(error.response.data)) {
        return setErrorMessage('Unexpected error');
      }

      const apiResponse = error.response.data;

      if (apiResponse.statusCode < 500) {
        return setErrorMessage(apiResponse.message);
      }

      return setErrorMessage('Unexpected Internal Error');
    } finally {
      resetRecaptcha();
      setIsLoading(false);
    }
  };

  const isSendMagicLinkButtonDisabled = !username
    || !isValidEmail(username)
    || (!recaptchaToken && !IS_TEST_USER)
    || isLoading;

  return (
    <div>
      <div className="flex items-center justify-center my-6 mb-6">
        <h2 className="text-2xl font-bold">Confirm email</h2>
      </div>

      <TextField
        name='username'
        type='email'
        className='mb-4'
        variant='filled'
        placeholder='Confirm email'
        value={username}
        onChange={handleInputChange}
      />

      <div className='w-full mb-4 text-center md:my-6'>
        <div className='inline-block transform scale-75 md:origin-top-left md:scale-100' >
          <ReCAPTCHA
            data-testid='self-registration-recaptcha'
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={setRecaptchaToken}
          />
        </div>
      </div>

      <Button
        className='mb-4 bg-darkGrey border-darkGrey'
        variant='contained'
        fullWidth
        disabled={isSendMagicLinkButtonDisabled}
        onClick={handleSignInWithoutPassword}
      >
        Send magic link
      </Button>

      {errorMessage && <p className='p-2 text-center text-red-600'>{errorMessage}</p>}
    </div>
  );
};
