import React from 'react';

export enum PasswordlessLoginSteps {
  INITIAL_PAGE = 'initial-page',
  SIGN_IN_WITH_PASSWORD = 'sign-in-with-password',
  SIGN_IN_WITHOUT_PASSWORD = 'sign-in-without-password',
  EMAIL_SENT = 'email-sent'
}

export interface PasswordlessLoginInputs {
  username: string,
  password: string,
  recaptchaToken: string,
}

export interface AuthTokenResponse {
  urlForRedirection: string;
}

export interface PasswordlessLoginFormProps {
  state: string;
  onForgotPasswordClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

export interface PasswordlessLoginBaseProps {
  inputs: PasswordlessLoginInputs,
  handleInputChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void,
  state: string,
}

type SetStep = React.Dispatch<React.SetStateAction<PasswordlessLoginSteps>>;

type SetRecaptchaToken = (token: string) => void;

export interface PasswordlessLoginInitialPageProps extends PasswordlessLoginBaseProps {
  setStep: SetStep,
}

export interface PasswordlessLoginWithPasswordProps extends PasswordlessLoginBaseProps {
  setRecaptchaToken: SetRecaptchaToken,
}

export interface PasswordlessLoginWithoutPasswordProps extends PasswordlessLoginBaseProps {
  setRecaptchaToken: SetRecaptchaToken,
  setStep: SetStep,
}
