import React from 'react';
import { iconPaths } from './icon-paths';
import { SvgIconProps as Props } from './SvgIcon.types';

export const SvgIcon: React.FC<Props> = ({
  size = 16, color = 'currentColor', name = 0, ...rest
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 25 25'
    fill={color}
    data-testid={`svgicon-${name}`}
    style={{
      display: 'inline',
      verticalAlign: 'baseline',
    }}
    {...rest}
  >
    <path d={iconPaths[name]} />
  </svg>
);
