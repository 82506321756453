import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { Button } from '../../components/button';
import { CountryCodeHint } from '../../components/country-code-hint';
import { SvgIcon } from '../../components/svg-icon';
import { TextField } from '../../components/textfield';
import { Tooltip } from '../../components/tooltip';
import { IS_TEST_USER, OAuth2Credentials, RECAPTCHA_KEY } from '../../constants';
import { removeSpaces } from '../../utils/strings';
import { AuthTokenPayload } from './Login.types';
import { isOAuthErrorResponse } from '../../utils/api';

export const LoginForm: React.FC = () => {
  const router = useRouter();
  const queryParams = router.query;
  const { state: searchStateParam } = queryParams;
  const authStateParam = Array.isArray(searchStateParam) ? searchStateParam[0] : searchStateParam;

  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [inputs, setInputs] = useState({ username: '', password: '' });
  const { username, password } = inputs;

  const [loginErrorMessage, setLoginErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    const parsedValue = removeSpaces(value);
    setInputs({ ...inputs, [name]: parsedValue });
  };

  const resetRecaptcha = () => {
    setRecaptchaToken('');
    reCaptchaRef.current?.reset();
  };

  const handleLogin = async () => {
    setIsLoading(true);
    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('password', password);
    params.set('recaptcha', recaptchaToken);
    params.set('state', authStateParam);
    params.set('username', username);

    try {
      const body = params.toString();
      const { data } = await axios.post<AuthTokenPayload>('/token', body, {
        headers: {
          Authorization: `Basic ${btoa(`${OAuth2Credentials.id}:${OAuth2Credentials.secret}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      const { urlForRedirection } = data;

      await router.push(urlForRedirection);
    } catch (error) {
      const INCORRECT_PWD = 'Incorrect username or password';
      if (axios.isAxiosError(error) && isOAuthErrorResponse(error.response.data)) {
        setLoginErrorMessage(error.response.data.errorDescription ?? INCORRECT_PWD);
      } else {
        setLoginErrorMessage(INCORRECT_PWD);
      }
      setIsLoading(false);
      resetRecaptcha();
    }
  };

  const isLoginDisabled = !username || !password || (!recaptchaToken && !IS_TEST_USER) || isLoading;

  return (
    <div>
      <div className='flex items-center justify-center my-6 mb-2'>
        <h2 className='text-2xl font-bold text-center'>Log in</h2>
        <Tooltip
          placement='top'
          content={
            <div className='w-64 p-2 text-xs font-light text-white'>
              Please enter your username, email address,
              phone number (including country code) or user/company ID number.
            </div>
          }
        >
          <SvgIcon
            name='help-circle'
            color='#474747'
            className='flex mt-2 ml-2 cursor-pointer'
            size={20}
          />
        </Tooltip>
      </div>

      <p className='p-2 text-center text-red-600'>{loginErrorMessage}</p>

      <div className='flex flex-col'>
        <TextField
          name='username'
          type='text'
          className='mb-3'
          variant='filled'
          placeholder='Username'
          value={username}
          onChange={handleInputChange}
          disabled={isLoading}
        />
        <TextField
          name='password'
          type='password'
          className='mb-3'
          variant='filled'
          placeholder='Password'
          value={password}
          onChange={handleInputChange}
          disabled={isLoading}
        />
      </div>

      <div className='w-full text-center md:my-6'>
        <div className='inline-block transform scale-75 md:origin-top-left md:scale-100' >
          <ReCAPTCHA
            data-testid='login-recaptcha'
            ref={reCaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={setRecaptchaToken}
          />
        </div>
      </div>

      <Button
        className='mb-4 bg-darkGrey border-darkGrey md:mb-10'
        variant='contained'
        fullWidth
        disabled={isLoginDisabled}
        onClick={handleLogin}
      >
        {isLoading ? <span>Logging in...</span> : <span>Log in</span> }
      </Button>

      <CountryCodeHint username={username} />
    </div>
  );
};
