export const API_URL = process.env.NEXT_PUBLIC_API_URL;

export const OAuth2Credentials = {
  id: process.env.NEXT_PUBLIC_CLIENT_ID,
  secret: process.env.NEXT_PUBLIC_CLIENT_SECRET,
};

export const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY;

export const IS_TEST_USER = process.env.NODE_ENV === 'test';

export const PASSWORDLESS_ENABLED = process.env.NEXT_PUBLIC_PASSWORDLESS_ENABLED === 'true';
