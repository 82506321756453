import React from 'react';
import classnames from 'classnames';
import { Button } from '../../../components/button';
import { HelpLinks } from '../../../components/help-links';
import { PasswordlessLoginFooterProps as Props } from './PasswordlessLoginFooter.types';

export const PasswordlessLoginFooter: React.FC<Props> = ({
  showBackButton, showForgotPassword, onBackButtonClick, onForgotPasswordClick,
}) => (
  <footer className='flex flex-col items-center mt-5 md:flex-row md:justify-between md:mb-0'>
    {(showBackButton || showForgotPassword) && (
      <div className='flex flex-col justify-between w-auto md:flex-row'>
        {showBackButton && (
          <Button
            className={classnames('p-2 text-sm font-normal underline md:mb-0', {
              'mb-10': !showForgotPassword,
            })}
            onClick={onBackButtonClick}
          >
            Back
          </Button>
        )}
        {showForgotPassword && (
          <Button
            className='p-2 mb-10 text-sm font-normal underline md:mb-0'
            onClick={onForgotPasswordClick}
          >
            Forgot your Password?
          </Button>
        )}
      </div>
    )}
    <HelpLinks className={classnames({ 'md:w-full': !showBackButton && !showForgotPassword })} />
  </footer>
);
