import React, { useState } from 'react';
import { removeSpaces } from '../../utils/strings';
import { PasswordlessLoginFormProps as Props, PasswordlessLoginSteps, PasswordlessLoginBaseProps } from './PasswordlessLogin.types';
import { PasswordlessLoginInitialPage } from './PasswordlessLoginInitialPage';
import { PasswordlessLoginWithPassword } from './PasswordlessLoginWithPassword';
import { PasswordlessLoginWithoutPassword } from './PasswordlessLoginWithoutPassword';
import { EmailSent } from '../../components/email-sent';
import { Layout } from '../../components/layout';
import { PasswordlessLoginFooter } from './passwordless-login-footer';

export const PasswordlessLoginForm: React.FC<Props> = ({ state, onForgotPasswordClick }) => {
  const [step, setStep] = useState(PasswordlessLoginSteps.INITIAL_PAGE);
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    recaptchaToken: '',
  });

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    const parsedValue = name !== 'password' ? removeSpaces(value) : value;
    setInputs({ ...inputs, [name]: parsedValue });
  };

  const setRecaptchaToken = (token: string) => {
    setInputs({ ...inputs, recaptchaToken: token });
  };

  const onBackButtonClick = () => {
    setStep(PasswordlessLoginSteps.INITIAL_PAGE);
    setInputs({ ...inputs, password: '', recaptchaToken: '' });
  };

  const showBackButton = [
    PasswordlessLoginSteps.SIGN_IN_WITH_PASSWORD,
    PasswordlessLoginSteps.SIGN_IN_WITHOUT_PASSWORD,
  ].includes(step);

  const showForgotPassword = step === PasswordlessLoginSteps.SIGN_IN_WITH_PASSWORD;

  const commonProps: PasswordlessLoginBaseProps = {
    inputs,
    handleInputChange,
    state,
  };

  return (
    <Layout footer={
      <PasswordlessLoginFooter
        showBackButton={showBackButton}
        showForgotPassword={showForgotPassword}
        onBackButtonClick={onBackButtonClick}
        onForgotPasswordClick={onForgotPasswordClick}
      />
    }>
      {
        {
          [PasswordlessLoginSteps.INITIAL_PAGE]:
            <PasswordlessLoginInitialPage
              {...commonProps}
              setStep={setStep}
            />,
          [PasswordlessLoginSteps.SIGN_IN_WITH_PASSWORD]:
            <PasswordlessLoginWithPassword
              {...commonProps}
              setRecaptchaToken={setRecaptchaToken}
            />,
          [PasswordlessLoginSteps.SIGN_IN_WITHOUT_PASSWORD]:
            <PasswordlessLoginWithoutPassword
              {...commonProps}
              setRecaptchaToken={setRecaptchaToken}
              setStep={setStep}
            />,
          [PasswordlessLoginSteps.EMAIL_SENT]: <EmailSent />,
        }[step]
      }
    </Layout>
  );
};
