import React from 'react';

export const CountryCodeHint: React.FC<{ username: string }> = ({
  username,
}) => {
  const isLoginContactOnlyNumbers = /^\d+$/.test(username);

  if (isLoginContactOnlyNumbers) {
    return (
      <div className='text-darkGrey text-sm font-normal mb-2.5 text-center'>
        To login with your phone number, please don't forget to add your{' '}
        <a className='underline text-warmGrey' href='https://countrycode.org/' target='_blank' rel='noopener noreferrer'>
          country code
        </a>
        {' '}(eg. +32 475 79 34 97)
      </div>
    );
  }
  return null;
};
