import React from 'react';
import { Button } from '../../components/button';
import { CountryCodeHint } from '../../components/country-code-hint';
import { SvgIcon } from '../../components/svg-icon';
import { TextField } from '../../components/textfield';
import { Tooltip } from '../../components/tooltip';
import { isValidEmail } from '../../utils/strings';
import { PasswordlessLoginSteps, PasswordlessLoginInitialPageProps as Props } from './PasswordlessLogin.types';

export const PasswordlessLoginInitialPage: React.FC<Props> = ({
  inputs, handleInputChange, setStep, state,
}) => {
  const { username } = inputs;

  const handleSignInWithPasswordButtonClick = () => {
    setStep(PasswordlessLoginSteps.SIGN_IN_WITH_PASSWORD);
  };

  const handleSignInWithoutPasswordButtonClick = () => {
    setStep(PasswordlessLoginSteps.SIGN_IN_WITHOUT_PASSWORD);
  };

  const isSignInWithPasswordButtonDisabled = !username || !state;
  const isSignInWithoutPasswordButtonDisabled = !isValidEmail(username) || !state;

  return (
    <div>
      <div className="flex items-center justify-center my-6 mb-6">
        <h2 className="text-2xl font-bold">Sign in</h2>
        <Tooltip
          placement="top"
          content={
            <div className="w-64 p-2 text-xs font-light text-white">
              Please enter your username, email address, phone number (including
              country code) or user/company ID number.
            </div>
          }
        >
          <SvgIcon
            name="help-circle"
            color="#474747"
            className="flex mt-2 ml-2 cursor-pointer"
            size={20}
          />
        </Tooltip>
      </div>

      <TextField
        name='username'
        type='text'
        className='mb-4'
        variant='filled'
        placeholder='Email, phone or ID'
        value={username}
        onChange={handleInputChange}
      />

      <Button
        className='mb-4 bg-darkGrey border-darkGrey'
        variant='contained'
        fullWidth
        disabled={isSignInWithPasswordButtonDisabled}
        onClick={handleSignInWithPasswordButtonClick}
      >
        Sign in with a password
      </Button>

      <Button
        className='mb-4 bg-darkGrey border-darkGrey'
        variant='contained'
        fullWidth
        disabled={isSignInWithoutPasswordButtonDisabled}
        onClick={handleSignInWithoutPasswordButtonClick}
      >
        Sign in without a password
      </Button>

      <CountryCodeHint username={username} />
    </div>
  );
};
