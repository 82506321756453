import React from 'react';
import { useRouter } from 'next/router';
import { LoginFooter, LoginForm } from '../containers/login-form';
import { Layout } from '../components/layout';
import { PASSWORDLESS_ENABLED } from '../constants';
import { PasswordlessLoginForm } from '../containers/passwordless-login';

const LoginPortal = () => {
  const router = useRouter();
  const queryParams = router.query;
  const { state: searchStateParam } = queryParams;
  const authStateParam = Array.isArray(searchStateParam) ? searchStateParam[0] : searchStateParam;

  const onForgotPasswordClick = async () => router.push(`/forgot-password?state=${authStateParam ?? ''}`);

  return (
    <>
      {PASSWORDLESS_ENABLED ? (
        <PasswordlessLoginForm state={authStateParam} onForgotPasswordClick={onForgotPasswordClick} />
      ) : (
        <Layout footer={<LoginFooter onForgotPasswordClick={onForgotPasswordClick} />}>
          <LoginForm />
        </Layout>
      )}
    </>
  );
};

export default LoginPortal;
